'use strict';

document.addEventListener('DOMContentLoaded', function () {

    $(document).on('click', 'a[href^="#"]', function (event) {
        event.preventDefault();
        $(".navbar-burger").removeClass("is-active");
        $(".navbar-menu").removeClass("is-active");
        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, 500);
    });

    // AOS init
    AOS.init({
        // Global settings:
        disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
        startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
        initClassName: 'aos-init', // class applied after initialization
        animatedClassName: 'aos-animate', // class applied on animation
        useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
        disableMutationObserver: false, // disables automatic mutations' detections (advanced)
        debounceDelay: 80, // the delay on debounce used while resizing window (advanced)
        throttleDelay: 130, // the delay on throttle used while scrolling the page (advanced)


        // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
        offset: 120, // offset (in px) from the original trigger point
        delay: 0, // values from 0 to 3000, with step 50ms
        duration: 800, // values from 0 to 3000, with step 50ms
        easing: 'ease', // default easing for AOS animations
        once: false, // whether animation should happen only once - while scrolling down
        mirror: false, // whether elements should animate out while scrolling past them
        anchorPlacement: 'top-bottom' // defines which position of the element regarding to window should trigger the animation

    });
});

var app = new Vue({
    el: '#app',
    data: {
        modalImage: false,
        modalImageSrc: '',
        showModal: false,
        rOne: Math.floor(Math.random() * 10),
        rTwo: Math.floor(Math.random() * 10),
        result: null,
        errorMessage: '',
        name: '',
        email: '',
        message: '',
        messageSend: false,
        allowsCookies: false,
        allowsOnlyFunctionalCookies: true

    },
    methods: {
        addImgEvents: function addImgEvents() {
            var _this = this;

            var srcList = $('img').map(function () {
                return this;
            }).get();
            srcList.forEach(function (img) {
                if (img.onclick == null) {
                    img.addEventListener('mousedown', function (event) {
                        _this.modalImageSrc = event.path[0].src;
                        _this.modalImage = true;
                    });
                }
            });
        },
        sendMessage: function sendMessage() {
            var _this2 = this;

            if (this.isEmpty(this.name)) {
                this.errorMessage = "Bitte Name eingeben.";
                return;
            }

            if (this.isEmpty(this.email)) {
                this.errorMessage = "Bitte E-Mail eingeben.";
                return;
            }

            if (this.isEmpty(this.message)) {
                this.errorMessage = "Bitte Nachricht eingeben.";
                return;
            }

            if (this.rOne + this.rTwo !== this.result) {
                this.errorMessage = "Rechnung fehlerhaft. Bitte überprüfen";
                return;
            }

            axios.post('https://lindland.de/api/mail.php', { email: this.email, name: this.name, message: this.message }).then(function (data) {
                _this2.messageSend = true;
            }).catch(function (error) {
                _this2.errorMessage = "Ein Fehler ist aufgetreten, bitte an den Betreiber wenden.";
                return;
            });
        },

        isEmpty: function isEmpty(str) {
            return !str || 0 === str.length;
        },

        doAllowCookies: function doAllowCookies() {
            this.allowsCookies = true;
            this.allowsOnlyFunctionalCookies = false;
            Cookies.set('allow_cookies', false);
        },
        doAllowFunctionalCookies: function doAllowFunctionalCookies() {
            this.allowsCookies = true;
            Cookies.set('allow_cookies', true);
        },
        checkCookie: function checkCookie() {
            /** Creates a DSGVO-Cookie consent */
            var has_consent = Cookies.get('allow_cookies');
            if (has_consent === undefined) {
                this.allowsCookies = false;
            } else {
                this.allowsCookies = true;
                if (has_consent === 'true') {
                    this.allowsOnlyFunctionalCookies = true;
                } else {
                    this.allowsOnlyFunctionalCookies = false;
                }
            }
        }
    },
    mounted: function mounted() {
        this.addImgEvents();
        $(".navbar-burger").on('click', function () {
            // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
            $(".navbar-burger").toggleClass("is-active");
            $(".navbar-menu").toggleClass("is-active");
        });

        this.checkCookie();
    }
});